import React, { useState, useMemo, useCallback, useEffect, forwardRef } from "react";

import {
  MaterialReactTable
} from "material-react-table";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AddIcon from "@mui/icons-material/Add";
import { BeatLoader } from "react-spinners";
import Loader from '../../Components/Loader/Loader';
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Popover from "@mui/material/Popover";
import { useSelector, useDispatch } from "react-redux";
import { openDrawer } from "../../actions/drawerActions";
import { DeletePortfolio } from "../../Apis/DeletePortfolio";
import { fetchPortfolio, togglePortfolioSelection, toggleClientSelection, togglePortfolioHedgeableFlag,selectPortfolio, selectClient } from "../../actions/portfolioActions";
import { addZeroes } from "../../Utilities/AddZeros";
import { customColumnSort } from "../../Utilities/CustomColumnSort";
import {
  fetchPortfolioDetailsIfNeeded,
  addTicker,
  deleteTicker,
} from "../../actions/portfolioDetailsActions";
import {
  handleApiError,
  showConfirmNotification,
} from "../../actions/notificationActions";
import Toaster from "../../Components/Toaster/Toaster";
import TagManager from "react-gtm-module";
import { clearPdfSummaryData } from '../../actions/pdfSummaryActions';
// import AutoCompleteTicker from "../AutoCompleteTicker/AutoCompleteTicker";

function PortfolioDetails({ portfolioId, portfolioName, hasSchwabAccount, portfolio, customer, allCustomers, hedgeableFlag }) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState({
    addTicker: false,
    deleteTicker: false,
  });
  const [ticker, setTicker] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const portfolioDetails = useSelector(
    (state) => state.portfolioDetails[portfolioId]
  );
  const activeDrawer = useSelector(state => state.drawer.activeDrawer);
  const portfolioDetailsState = useSelector(state => state.portfolioDetails);
  const availableTickers = useSelector(
    (state) => state.availableTickers.availableTickers
  );

  const [pendingDrawer, setPendingDrawer] = useState(null);
  const [loading, setLoading] = useState({});
  const [openPortfolios, setOpenPortfolios] = useState({});
  const [csvData, setCSVData] = useState(false)

  const toggleTable = (portfolioId) => {
    setOpenPortfolios(prevOpenPortfolios => ({
        ...prevOpenPortfolios,
        [portfolioId]: !prevOpenPortfolios[portfolioId]
    }));
  };

  const handleTickerChange = (e,newValue) => {
    const referenceTicker = availableTickers.find(
      (refTicker) => refTicker.symbol === newValue.symbol.toUpperCase()
    );
    if (!referenceTicker) {
      setErrorMessage("Symbol not recognized");
    } else {
      setErrorMessage("");
    }
    setTicker(newValue.symbol.toUpperCase());
  };

  const open = Boolean(anchorEl);
  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const quantityNum = parseFloat(value);
    let newErrorMessage = "";
  
    if (value && isNaN(quantityNum)) {
      newErrorMessage = "Please enter a valid number.";
    } else if (quantityNum === 0) {
      newErrorMessage = "Quantity cannot be zero.";
    }
    setQuantity(value);
    setErrorMessage(newErrorMessage);
  };

  const handleAddTicker = () => {
    const quantityNum = parseFloat(quantity);
    const hedgeableItems = portfolioDetailsState[portfolioId]?.details?.PortfolioLineItem?.Hedgeable;
    if (!ticker && !quantityNum) {
      setErrorMessage("Symbol and quantity field cannot be empty");
      return;
    } else if (quantityNum === 0) {
      setErrorMessage("Quantity cannot be zero.");
      return;
    } else if (!ticker) {
      setErrorMessage("Symbol field cannot be empty");
      return;
    } else if (hedgeableItems && hedgeableItems.some(item => item.Ticker === ticker)) {
      setErrorMessage("Symbol is already present in the Portfolio");
      return;
    }
  
    setLoadingState((prev) => ({ ...prev, addTicker: true }));
  
    dispatch(addTicker(portfolioId, ticker, quantity)).finally(() => {
      setLoadingState((prev) => ({ ...prev, addTicker: false }));
      setTicker("");
      setQuantity("");
      setErrorMessage("");
      TagManager.dataLayer({
        dataLayer: {
          event: "Add Ticker",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
      dispatch(fetchPortfolio());
    });
  };

  const handleDeleteTicker = (ticker) => {
    setLoadingState((prev) => ({ ...prev, deleteTicker: true }));
    dispatch(deleteTicker(portfolioId, ticker)).finally(() => {
      setLoadingState((prev) => ({ ...prev, deleteTicker: false }));
      TagManager.dataLayer({
        dataLayer: {
          event: "Delete Ticker",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
      dispatch(fetchPortfolio());
    });
  };

  const hedgeableColumns = useMemo(
    () => [
      {
        accessorKey: "Ticker",
        header: "SYMBOL",
        size: 100,
      },
      {
        accessorKey: "CompanyName",
        header: "DESCRIPTION",
        size: 250,
      },
      {
        accessorKey: "Quantity",
        header: "QUANTITY",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'Quantity')
      },
      {
        accessorKey: "ClosePrice",
        header: "PRICE",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'ClosePrice')
        
      },
      {
        accessorKey: "TotalValue",
        header: "MARKET VALUE",
        sorting: true,
        sortingFn: (a, b) => customColumnSort(a, b,'TotalValue')
        
      },
      {
        accessorKey: "MarketWeight",
        header: "MARKET WEIGHT",
        sorting:true,
        sortingFn:(a,b) => customColumnSort(a,b,'MarketWeight'),
        size: 160,
        
      },
      {
        accessorKey: "actions",
        header: "ACTIONS",
        size: 110,
        
        Cell: ({ row }) =>
          !hasSchwabAccount && (
            <button
              className="deleterow"
              onClick={() => handleDeleteTicker(row.original.Ticker)}
            >
              <img src="./Assets/trash.svg" alt="Delete" />
            </button>
          ),
      },
    ],
    [hasSchwabAccount]
  );

  const unhedgeableColumns = useMemo(
    () => [
      {
        accessorKey: "Ticker",
        header: "SYMBOL",
        size: 100,
      },
      {
        accessorKey: "Quantity",
        header: "QUANTITY",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'Quantity')
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!portfolioDetails?.details?.PortfolioLineItem) {
      return [];
    }
    const portfolioLineItems = hedgeableFlag
      ? portfolioDetails.details.PortfolioLineItem.Unhedgeable
      : portfolioDetails.details.PortfolioLineItem.Hedgeable;

    const totalPortfolioValue = parseFloat(
      portfolioDetails.details.TotalPortfolioValue
    );

    return portfolioLineItems.map((item) => ({
      Ticker: item.Ticker,
      CompanyName: item.CompanyName,
      Quantity: Number(addZeroes(Number(parseFloat(item.Quantity).toFixed(2)))).toLocaleString('US'),
      ClosePrice:
        item.ClosePrice === "-" ? "-" : Number(addZeroes(Number(parseFloat(item.ClosePrice).toFixed(2)))).toLocaleString('US',currObj),
      TotalValue: Number(addZeroes(Number(parseFloat(item.Quantity * item.ClosePrice).toFixed(2)))).toLocaleString('US',currObj),
      MarketWeight: `${(
        ((item.Quantity * item.ClosePrice) / totalPortfolioValue) *
        100
      ).toFixed(2)}%`,
    }));
  }, [portfolioDetails, hedgeableFlag]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePortfolioDelete = (portfolioid) => {
    handleClose();
    dispatch(
      showConfirmNotification(
        "Are you sure you want to delete this portfolio ?",
        () => confirmDelete(portfolioid)
      )
    );
  };

  const confirmDelete = async (portfolioid) => {
    setLoadingState((prev) => ({ ...prev, deleteTicker: true }));
    const data = await DeletePortfolio(portfolioid);
    if (data.status === 200) {
      TagManager.dataLayer({
        dataLayer: {
          event: "Portfolio Deleted",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
      dispatch(fetchPortfolio());
    } else {
      handleApiError(data, dispatch);
    }
    setLoadingState((prev) => ({ ...prev, deleteTicker: false }));
  };

  const handlePortfolioClick = (portfolioId,action) => {
    dispatch(togglePortfolioSelection(portfolioId,action));
    dispatch(fetchPortfolioDetailsIfNeeded(portfolioId));
    dispatch(clearPdfSummaryData(portfolioId))
    toggleTable(portfolioId);
    //dispatch(selectPortfolio(portfolioId,action));
  };

  const openDrawerWithCheck = (drawerType, portfolio, customer) => {

    if (activeDrawer === drawerType) {
        return;
    }

    const loadingKey = `${portfolio.PortfolioId}-${drawerType}`;
    const details = portfolioDetailsState[portfolio.PortfolioId];

    const startLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: true }));
    const stopLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: false }));

    if (!details || details.needsUpdate || details.isLoading) {
        startLoading();
        dispatch(fetchPortfolioDetailsIfNeeded(portfolio.PortfolioId))
            .finally(stopLoading);
        setPendingDrawer({
            type: drawerType,
            portfolioId: portfolio.PortfolioId,
            name: `${customer.FirstName} ${customer.LastName}`,
            portfolioName: portfolio.PortfolioName
        });
    } else {
        const isEmpty = !(details.details?.PortfolioLineItem?.Hedgeable?.length > 0);
        dispatch(openDrawer(drawerType, {
            portfolioId: portfolio.PortfolioId,
            Name: `${customer.FirstName} ${customer.LastName}`,
            portfolio: portfolio.PortfolioName,
            isEmpty: isEmpty
        }));
    }
  };

  const handleDrawerClick = (drawer,portfolio,customer) =>{
    openDrawerWithCheck(drawer, portfolio, customer);
    handlePortfolioClick(portfolio.PortfolioId,'drawer');
    dispatch(selectClient(customer.CustomerId));
    //dispatch(selectPortfolio(portfolio.PortfolioId));
  }

  const handleCloneClick = (portfolio,customer) =>{
    dispatch(openDrawer('ClonePortfolio', { portfolioId: portfolio.PortfolioId, portfolio: portfolio.PortfolioName, customers: allCustomers, client: customer.FirstName + ' ' + customer.LastName }))
    handlePortfolioClick(portfolio.PortfolioId,'drawer');
  }

  const toggleHedgeableFlag = (portfolioId,CustomerId) => {
    dispatch(togglePortfolioHedgeableFlag(portfolioId,CustomerId));
  };

  const convertArrayToCSV = (array) => {
    const headers = Object.keys(array[0]).join(',');
    const rows = array.map(obj => Object.values(obj).join(',')).join('\n');
    return `${headers}\n${rows}`;
  };
  const handlePortfolioDownload = (e) => {
    e.preventDefault();
    let data = [];
    const { PortfolioLineItem, TotalPortfolioValue, PortfolioName } = portfolioDetails.details;
    for (const status in PortfolioLineItem) {
        const isHedgeable = status === 'Hedgeable';
        PortfolioLineItem[status].forEach(item => {
            const marketWeight = ((parseFloat(item.TotalValue) / parseFloat(TotalPortfolioValue)) * 100).toFixed(2);
            data.push({
                ticker: item.Ticker,
                company_name: item.CompanyName,
                price: item.ClosePrice,
                quantity: item.Quantity,
                market_weight: marketWeight,
                market_value: item.TotalValue,
                recognized: isHedgeable
            });
        });
    }
    if (data.length === 0) {
        console.error("No data to download");
        return;
    }
    setCSVData(data);
    const csv = convertArrayToCSV(data);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    element.download = `${PortfolioName}_${formattedDate}.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  return (
    <>
      <div className="toast-container">
        <Toaster />
      </div>
      {portfolioDetails.isLoading ||
      loadingState.addTicker ||
      loadingState.deleteTicker ? (
        <div className="details-table-container " style={{ height: "300px" }}>
          <div className="loading-overlay">
            <Loader/>
          </div>
        </div>
      ) : (
        <>
          <div className="portfolio-header-container">
            
          </div>
          <MaterialReactTable
            columns={hedgeableFlag?unhedgeableColumns:hedgeableColumns}
            data={data}
            enableColumnActions={false}
            enableDensityToggle={false}
            enableStickyHeader
            enableColumnResizing
            enableColumnOrdering
            enableRowPinning
            enablePagination={false}
            enableFullScreenToggle={false}
            muiTableBodyProps={{
              sx: {
                maxHeight: "300px", // Set the max height of the table body for scrolling
              },
            }}
            initialState={{
              density: "compact", // Set the initial density to 'compact'
            }}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "white", // Adjust the header cell styling
                position: "sticky", // Stick the header
                top: 0, // Stick to the top
                zIndex: 1, // Ensure it’s above other content
              },
            }}
            renderTopToolbarCustomActions={() => (
              <div className="operations">
                <div className="switchcontent">
                Hedgeable Assets
                <div className="toggle-switch-small">
                  <input
                    type="checkbox"
                    id="mode-toggle"
                    checked={hedgeableFlag}
                    onChange={() => toggleHedgeableFlag(portfolioId,customer.CustomerId)}
                  ></input>
                  <label htmlFor="mode-toggle" className="toggle-label-small"></label>
                </div>
                Unhedgeable Assets
              </div>
                <button className="ai-btn solid secondary mhide" onClick={() => handleDrawerClick('Analytics', portfolio, customer)}>
                  <img src="Assets/graph.svg" alt="" /> Portfolio Analytics
                </button>
                <button className="ai-btn solid primary" onClick={() => handleDrawerClick('Recommendation', portfolio, customer)}>
                  <img src="Assets/shield.svg" alt="" /> Shield Recommendations
                </button>
                <div className="portfolio-details">
              {!hasSchwabAccount && (
                <button
                  className="pf-action-button"
                  onClick={(e) => handleActionClick(e)}
                >
                  Actions <span className="arrow-down" />
                </button>
              )}
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <div className="popoverContainer">
                    <button
                        onClick={() => {
                            dispatch(openDrawer('EditPortfolio', { portfolioDetails: portfolioDetails.details?.PortfolioLineItem?.Hedgeable?.length > 0 ? portfolioDetails.details : { PortfolioId: portfolioId, PortfolioName: portfolioName } }));
                            handleClose();
                        }}
                        className="popoverButton"
                    >
                        <span>Edit Portfolio</span>
                        <img className="product_card_img" src='./Assets/Typing.svg' alt="Edit" />
                    </button>
                    <button
                        onClick={() => handlePortfolioDelete(portfolioId)}
                        className="popoverButton"
                    >
                        <span>Delete Portfolio</span>
                        <img className="product_card_img" src="./Assets/trash.svg" alt="Delete" />
                    </button>
                    <button
                        onClick={(e) => handlePortfolioDownload(e)}
                        className="popoverButton"
                    >
                        <span>Download Portfolio</span>
                        <img className="product_card_img" src="./Assets/Export.png" alt="Delete" />
                    </button>
                </div>
            </Popover>
                {/* <button className="ai-btn solid blue" onClick={() => handleCloneClick(portfolio,customer)}>
                  <img src="Assets/clone.svg" alt="" /> Clone Portfolio
                </button> */}
              </div>
            )}
            renderBottomToolbarCustomActions={() => (
              <>
              <div
                    className="addticker"
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                  {/* <AutoCompleteTicker
                      onTickerChange={handleTickerChange}
                  /> */}
                  <input
                      className="ai-input"
                      type="text"
                      placeholder="Symbol"
                      value={ticker}
                      onChange={(e) => handleTickerChange(null,{symbol:e.target.value})}
                      sx={{ width: "100px" }}
                    ></input>
                    <input
                      className="ai-input"
                      type="number"
                      placeholder="Quantity"
                      value={quantity}
                      onChange={handleQuantityChange}
                      step="any"
                      sx={{ width: "100px" }}
                    ></input>
                    <button
                      variant="contained"
                      color="primary"
                      onClick={handleAddTicker}
                    >
                      +
                    </button>
                  </div>
                  <p className="ai-error-message">{errorMessage}</p>
                </>
            )}
            muiTableFooterProps={{
              sx: {
                position: "sticky", // Stick the footer
                bottom: 0, // Stick to the bottom
                zIndex: 1, // Ensure it’s above other content
              },
            }}
          />
        </>
      )}
    </>
  );
}

export default PortfolioDetails;
