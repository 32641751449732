import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    getNextFriday,
    isFriday,
} from "../../Utilities/DateUtils";

const CustomDatePicker = ({ defaultDate, onDateSelect }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    // Ensure the minimum selectable date is the next Friday
    const minDate = getNextFriday();

    // Sync defaultDate prop to the local state
    useEffect(() => {
        if (defaultDate) {
            const utcDate = new Date(defaultDate); // Parse UTC string to Date
            if (!isNaN(utcDate.getTime())) {
                setSelectedDate(new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));
            }
        }
    }, [defaultDate]);

    // Handle date selection and convert to UTC
    const handleDateChange = (date) => {
        if (date) {
            // Convert the local date to a UTC string
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            setSelectedDate(date);
            onDateSelect(utcDate.toISOString().split("T")[0]); // Return YYYY-MM-DD
        } else {
            setSelectedDate(null);
            onDateSelect(null);
        }
    };

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            minDate={minDate}
            filterDate={isFriday}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
            
        />
    );
};

export default CustomDatePicker;
