import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SummaryAnalytics.scss';
import { GetMarketShieldPlus } from '../../Apis/GetMarketShieldPlus';
import Spinner from '../Spinner/Spinner';
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from 'react-gtm-module';
import { FetchRiskContribution } from "../../Apis/FetchRiskContribution";
import { GetBacktestGraphData } from '../../Apis/GetBacktestGraphData';
import BacktestV2Graph from '../BackTestV2/BackTestV2Graph';
import CaptureButtons from "../CaptureButtons/CaptureButtons";
import { updatePdfData } from "../../actions/pdfDataActions";
import Loader from '../../Components/Loader/Loader';
import moment from 'moment';


function SummaryAnalytics({portfolioId,isEmpty,activeTabLabel}) {
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({
        marketShield: true,
        riskContributionData: true,
        backtestData: true
    });

    const [shields, setShields] = useState({
        marketShield: {
            protectionLevel: 95,
            protectionPeriod: "3 Month",
            assetAllocation: "90-10",
        }
    });
    const totalPremium = sessionStorage.getItem('totalPremium');
    const totalCost = sessionStorage.getItem('totalCost');
    const portfolioDetails = useSelector(state => state.portfolioDetails[portfolioId].details);
    const riskContributionData = useSelector((state) => state.pdfData["Risk Contribution"]);
    const backtestApiData = useSelector((state) => state.pdfData["Summary Analytics Backtest"]);
    const backtestTabDataFlag = useSelector((state) => state.pdfData["Backtest"]) ? true : false;
    const marketShieldData = useSelector((state) => state.pdfData["Market Shield"]);
    const [BtData, setBtData] = React.useState([]);
    const [totalMarketCont, setTotalMarketCont] = useState(0);
    const [totalIdioCont, setTotalIdioCont] = useState(0);
    const [totalIsoCont, setTotalIsoCount] = useState(0);
    const [highIdioStocks, setHighIdioStocks] = useState(0);
    const [summaryObj, setSummaryObj] = useState([]);
    const isPortfolioLoading = useSelector(state => state.portfolioDetails[portfolioId]?.isLoading);
    const [userSelectedData, setUserSelectedData] = useState([]);

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const handleRiskContributionData = (data, vix_all_data) => {
        if (data.status === 200) {
            let totalMarketContribution = 0;
            let totalIdiosyncraticPortion = 0;
            let totalIsolatedRisk = 0;
            let highIdiosyncraticRiskItems = [];

            for (const [key, value] of Object.entries(data.result.risk_contribution)) {
                totalMarketContribution += value.market_contribution;
                totalIdiosyncraticPortion += value.idiosyncratic_portion;
                totalIsolatedRisk += value.isolated_risk;

                if (value.idiosyncratic_portion > 0.03) {
                    highIdiosyncraticRiskItems.push(key);
                }
            }
            setTotalMarketCont(totalMarketContribution)
            setTotalIdioCont(totalIdiosyncraticPortion)
            setTotalIsoCount(totalIsolatedRisk)
            if (highIdiosyncraticRiskItems.length === 0) {
                highIdiosyncraticRiskItems.push("-")
            }
            setHighIdioStocks(highIdiosyncraticRiskItems)
            dispatch(
                updatePdfData("Risk Contribution", {
                allData: { risk_data: data, vix_data: vix_all_data },
                portfolioid: portfolioId,
                dataFlag: "Data Available",
                })
            );
        }
        else {
            dispatch(
                updatePdfData("Risk Contribution", {
                  dataFlag: "Data Not Available",
                  portfolioid: portfolioId,
                })
            );
        }
    }

    const handleBacktestData = (data, date1, date2, title,periodSelected) => {
        if (Object.keys(data).length > 0){
            setBtData(data);
            dispatch(
                updatePdfData("Summary Analytics Backtest", {
                  allData: {
                    btdata: data,
                    period: 90,
                    btlevel: 0.95,
                    startDate: new Date(date1),
                    endDate: new Date(date2),
                    event: title,
                    periodSelected:periodSelected,
                    protection_model:'Asset Allocation Equivalent',
                    asset_allocation_range:[0.60, 0.40],
                    target_risk:0.1
                  },
                  portfolioid: portfolioId,
                  dataFlag: "Data Available",
                })
            );
            if(!backtestTabDataFlag){
                dispatch(
                    updatePdfData("Backtest", {
                      allData: {
                        btdata: data,
                        period: 90,
                        btlevel: 0.95,
                        startDate: new Date(date1),
                        endDate: new Date(date2),
                        event: title,
                        periodSelected:periodSelected,
                        protection_model:'Asset Allocation Equivalent',
                        asset_allocation_range:[0.60, 0.40],
                        target_risk:0.1
                      },
                      portfolioid: portfolioId,
                      dataFlag: "Data Available",
                    })
                );
            }
            let lyst = []
            lyst.push({
                "protection_level": 0.95,
                "protection_period": 90,
                "historicalEventSelected":'',
                "activePeriodBtn":'',
                "startDate": new Date(date1),
                "endDate": new Date(date2),
                "protection_model":'Asset Allocation Equivalent',
                "asset_allocation_range":'60-40',
                "target_risk":0.1
            });
            setUserSelectedData([...lyst]);
        } else{
            dispatch(
                updatePdfData("Summary Analytics Backtest", {
                  portfolioid: portfolioId,
                  dataFlag: "Data Not Available",
                })
              );
        }
    }

    const handleMarketShieldData = (data) =>{
        if (data.status === 200) {
            dispatch(
                updatePdfData("Market Shield", {
                    allData: { market_shield_data: data },
                    portfolioid: portfolioId,
                    dataFlag: "Data Available",
                })
            );
            setLoadingState(prev => ({ ...prev, marketShield: false }));
        } else {
            handleApiError(data, dispatch);
            dispatch(
                updatePdfData("Market Shield", {
                  portfolioid: portfolioId,
                  dataFlag: "Data Not Available",
                })
            );
            setLoadingState(prev => ({ ...prev, marketShield: false }));
        }
    }

    useEffect(() => {
        if(!isEmpty){
            setLoadingState(prev => ({ ...prev, riskContributionData: true }));
            if (portfolioId && (activeTabLabel === "Summary Analytics")) {
                if (riskContributionData && portfolioId === riskContributionData.portfolioid && riskContributionData.dataFlag === "Data Available") {
                    handleRiskContributionData(riskContributionData.allData.risk_data, riskContributionData.allData.vix_data)
                    setLoadingState(prev => ({ ...prev, riskContributionData: false }));
                } else {
                    FetchRiskContribution(portfolioId).then((data) => {
                        if (data.status === 200) {
                            handleRiskContributionData(data,data.result["graph_data"])
                            setLoadingState(prev => ({ ...prev, riskContributionData: false }));
                        }
                        else {
                            handleApiError(data, dispatch);
                            setLoadingState(prev => ({ ...prev, riskContributionData: false }));
                        }
                    });
                }
            }
        }
    }, [portfolioId]);

    useEffect(() => {
        if(!isEmpty){
            setLoadingState(prev => ({ ...prev, backtestData: true }));
            const startDate = new Date(new Date().setMonth(new Date().getMonth() - 12))
            const endDate = new Date(new Date().setDate(new Date().getDate() - 7))
            const date1 = startDate.toISOString().split("T")[0].toString();
            const date2 = endDate.toISOString().split("T")[0].toString();

            if (backtestApiData &&
                backtestApiData.portfolioid === portfolioId &&
                90 === backtestApiData.allData.period &&
                0.95 === backtestApiData.allData.btlevel &&
                backtestApiData.dataFlag === "Data Available" &&
                date1 ===
                backtestApiData.allData.startDate
                    .toISOString()
                    .split("T")[0]
                    .toString() &&
                date2 ===
                backtestApiData.allData.endDate.toISOString().split("T")[0].toString()) 
            {
                handleBacktestData(
                    backtestApiData.allData.btdata,
                    date1,
                    date2,
                    "",
                    ""
                );
                setLoadingState(prev => ({ ...prev, backtestData: false }));
            } else {
                GetBacktestGraphData(portfolioId, date1, date2, 0.95, 90,'Asset Allocation Equivalent',[0.60,0.40],0.1).then((data) => {
                    console.log('backtest new response',data)
                    handleBacktestData(data, date1, date2,"","");
                    setLoadingState(prev => ({ ...prev, backtestData: false }));
                });
            }
        }
    }, [portfolioId]);

    useEffect(() => {
        if(!isEmpty){
            setLoadingState(prevState => ({ ...prevState, marketShield: true }));
            if(marketShieldData && portfolioId === marketShieldData.portfolioid && marketShieldData.dataFlag === "Data Available"){
                handleMarketShieldData(marketShieldData.allData.market_shield_data)
                setLoadingState(prev => ({ ...prev, marketShield: false }));
            } else{
                let formattedDate = moment(moment().add(2, 'month').toDate()).format('YYYY-MM-DD');
                GetMarketShieldPlus(portfolioId,formattedDate).then((data) => {
                    console.log(" daya in ms plsu", data)

                    handleMarketShieldData(data)
                    setLoadingState(prev => ({ ...prev, marketShield: false }));
                }).catch(error => {
                    console.error("Error fetching market shield data:", error);
                    handleApiError(error, dispatch);
                    setLoadingState(prevState => ({ ...prevState, marketShield: false }));
                });
            }
        }
    }, [portfolioId]);

    useEffect(() => {
        if (!isEmpty && riskContributionData && marketShieldData && BtData) {
            let lyst = [];
            lyst.push({
                "MARKET VALUE HEDGEABLE ($)":portfolioDetails?.TotalPortfolioValue ? `$${portfolioDetails.TotalPortfolioValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A',
                "NUMBER OF HOLDINGS":(portfolioDetails?.PortfolioLineItem?.Hedgeable?.length ?? 0) + (portfolioDetails?.PortfolioLineItem?.Unhedgeable?.length ?? 0),
                "PORTFOLIO VOLATILITY (ANNUALIZED)":riskContributionData?.allData?.risk_data?.result?.portfolio_risk?.toFixed(2) ?? 'N/A',
                "INDEX VOLATILITY (HISTORICAL)":riskContributionData?.allData?.risk_data?.result?.historical_market_risk?.toFixed(2) ?? 'N/A',
                "BEST MATCH INDEX":riskContributionData?.allData?.risk_data?.result?.selected_index ?? 'N/A',
                "R-SQUARE":marketShieldData?.allData?.market_shield_data?.result?.RSq ?? 'N/A',
                "BETA":marketShieldData?.allData?.market_shield_data?.result?.Beta?.toFixed(2) ?? 'N/A',
                "TRACKING ERROR":marketShieldData?.allData?.market_shield_data?.result?.TrackingError?.toFixed(2) ?? 'N/A',
                "MARKET RISK CONTRIBUTION":`${totalMarketCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                "IDIOSYNCRATIC RISK CONTRIBUTION":`${totalIdioCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                "DIVERSIFICATION BENEFIT":`${totalIsoCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                "HOLDINGS WITH HIGH IDIOSYNCRATIC RISK":highIdioStocks,
                "PORTFOLIO IMPLIED VOLATILITY":riskContributionData?.allData?.risk_data?.result?.port_implied_vol?.toFixed(2) ?? 'N/A',
                "MARKET IMPLIED VOLATILITY":riskContributionData?.allData?.risk_data?.result?.market_implied_level?.toFixed(2) ?? 'N/A',
                "COST OF MARKET SHIELD (INDEX HEDGE) ($)": `$${(marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} (${((marketShieldData?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0) / (portfolioDetails?.TotalPortfolioValue ?? 1) * 100).toFixed(2)}%)`,
                "EXPIRATION (DAYS TO MATURITY)":marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.ExpDtInDays ?? 'N/A',
                "ADJUSTED PROTECTION LEVEL ON THE INDEX":(marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.ProtLevelNew ?? 0).toFixed(2) ?? 'N/A'
            });
            setSummaryObj(lyst);
        }
    },[marketShieldData,riskContributionData,BtData,portfolioDetails,portfolioId])

    return (
        <>
            {isPortfolioLoading? (
                <div className="loading-overlay">
                    <Loader />
                </div>
            ) : (
                <>
                    {!isEmpty ? (
                    <>
                        <div className="toast-container">
                            <Toaster />
                        </div>
                        <div className="drawercontent">
                            <div>
                                {loadingState.backtestData? (
                                    <div className='spinner-container'>
                                    <Spinner variant="dark" size="big"/>
                                    </div>
                                ):(
                                    <div className="ai-backtest-graph sum-container">
                                        <CaptureButtons elementType='chart' id='backtestgraph' chartName="Summary_Analytics_Chart" activeTab={"Summary Analytics"} userSelectedData={userSelectedData[0]}></CaptureButtons>
                                        <div className="riskhead" id="graphHeader">
                                            <h2>Market Shield Backtest</h2>
                                        </div>
                                        <BacktestV2Graph rawData={BtData} showPortfolioShield={false} summary={false} />
                                    </div>
                                )}
                            </div>
    
                            <div className='shieldrecc two sum-container'>
                                <CaptureButtons elementType='table' chartName="Summary_Analytics_Table_1" activeTab={"Summary Analytics"} tableHeaders={summaryObj[0]} tableData={summaryObj[0]}></CaptureButtons>
                                <div className='split'>
                                    <div className="card-container">
                                        <div className="header-card">
                                            <div>MARKET VALUE HEDGEABLE ($)</div>
                                            <div>NUMBER OF HOLDINGS</div>
                                            <div>PORTFOLIO VOLATILITY (ANNUALIZED)</div>
                                            <div>INDEX VOLATILITY (HISTORICAL)</div>
                                            <div>BEST MATCH INDEX</div>
                                            <div>R-SQUARE</div>
                                            <div>BETA</div>
                                            <div>TRACKING ERROR</div>
                                        </div>
                                        {
                                            <div key={1} className="card small">
                                                {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                                    <div className="spinner-container">
                                                        <Spinner variant="dark" size="big"/> 
                                                    </div> :
                                                    <>
                                                        <div>{portfolioDetails?.TotalPortfolioValue ? `$${portfolioDetails.TotalPortfolioValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A'}</div>
                                                        <div>{(portfolioDetails?.PortfolioLineItem?.Hedgeable?.length ?? 0) + (portfolioDetails?.PortfolioLineItem?.Unhedgeable?.length ?? 0)}</div>
                                                        <div>{riskContributionData?.allData?.risk_data?.result?.portfolio_risk?.toFixed(2) ?? 'N/A'}</div>
                                                        <div>{riskContributionData?.allData?.risk_data?.result?.historical_market_risk?.toFixed(2) ?? 'N/A'}</div>
                                                        <div>{riskContributionData?.allData?.risk_data?.result?.selected_index ?? 'N/A'}</div>
                                                        <div>{marketShieldData?.allData?.market_shield_data?.result?.RSq ?? 'N/A'}</div>
                                                        <div>{marketShieldData?.allData?.market_shield_data?.result?.Beta?.toFixed(2) ?? 'N/A'}</div>
                                                        <div>{marketShieldData?.allData?.market_shield_data?.result?.TrackingError?.toFixed(2) ?? 'N/A'}</div>
                                                    </>
                                                }
                                            </div>
                                        }                        
                                    </div>
                                </div>
                                <div className='split'>
                                    <div className="card-container">
                                        <div className="header-card">
                                            <div>MARKET RISK CONTRIBUTION</div>
                                            <div>IDIOSYNCRATIC RISK CONTRIBUTION</div>
                                            <div>DIVERSIFICATION BENEFIT</div>
                                            <div>HOLDINGS WITH HIGH IDIOSYNCRATIC RISK</div>
                                        </div>
                                        {
                                            <div key={1} className="card small">
                                                {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                                    <div className="spinner-container">
                                                        <Spinner variant="dark" size="big"/> 
                                                    </div> :
                                                    <>
                                                        {/* <div></div> */}
                                                        <div>{`${totalMarketCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                                        <div>{`${totalIdioCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                                        <div>{`${totalIsoCont.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                                                        <div>{highIdioStocks}</div>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="card-container">
                                        <div className="header-card">
                                            <div>PORTFOLIO IMPLIED VOLATILITY</div>
                                            <div>MARKET IMPLIED VOLATILITY</div>
                                        </div>
                                        {
                                            <div key={1} className="card small">
                                                {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                                    <div className="spinner-container">
                                                        <Spinner variant="dark" size="big"/> 
                                                    </div> :
                                                    <>
                                                        {/* <div></div> */}
                                                        <div>{riskContributionData?.allData?.risk_data?.result?.port_implied_vol?.toFixed(2) ?? 'N/A'}</div>
                                                        <div>{riskContributionData?.allData?.risk_data?.result?.market_implied_level?.toFixed(2) ?? 'N/A'}</div>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="card-container">
                                        <div className="header-card">
                                            <div>COST OF MARKET SHIELD (INDEX HEDGE) ($)</div>
                                            <div>EXPIRATION (DAYS TO MATURITY)</div>
                                            <div>ADJUSTED PROTECTION LEVEL ON THE INDEX</div>
                                        </div>
                                        {
                                            <div key={1} className="card small">
                                                {loadingState["riskContributionData"] && loadingState["marketShield"] ?
                                                    <div className="spinner-container">
                                                        <Spinner variant="dark" size="big"/> 
                                                    </div> :
                                                    <>
                                                        {/* <div></div> */}
                                                        <div>
                                                            {`$${(marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                                            ({((marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.Cost ?? 0) / (portfolioDetails?.TotalPortfolioValue ?? 1) * 100).toFixed(2)}%)
                                                        </div>
                                                        <div>{marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.ExpDtInDays ?? 'N/A'}</div>
                                                        <div>{(marketShieldData?.allData?.market_shield_data?.result?.ChoosenOption?.quarter?.[0.95]?.ProtLevelNew ?? 0).toFixed(2) ?? 'N/A'}</div>
                                                    </>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div >
                    </>)
                    :(
                    <div className="drawercontent">
                        <p>Portfolio is Empty. Please add stock to proceed</p>
                    </div>
                    )}

                </>
            )}
        </>
    );

};

export default SummaryAnalytics;
