import React, { useEffect, useState } from 'react';
import { utils, writeFile } from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { showNotification } from '../../actions/notificationActions';
import { addZeroes } from '../../Utilities/AddZeros';
import { RollOrSell } from '../../Apis/RollAndSellHandler';
import { handleApiError } from '../../actions/notificationActions';
import { fetchPortfolioDetailsIfNeeded,
        setPortfolioNeedsUpdate } from '../../actions/portfolioDetailsActions';
import { formatOptionSymbol } from '../../Utilities/FormatContract';
import { result } from 'lodash';
import moment from 'moment';

const ShoppingCart = ({ visibleCartItems, setVisibleCartItems, portfolioId, selectedPrice }) => {
    const dispatch = useDispatch();
    const [pendingContracts, setPendingContracts] = useState({
        callWriting: [],
        putBuying: [],
        ShieldRecommendation: [],
        indexPuts: [],
    });
    const portfolioDetailsState = useSelector(state => state.portfolioDetails);
    console.log("portfolioDetailsState", portfolioDetailsState, selectedPrice)
    const mergeOptimizerData = (optionHoldings) => {
        const optimizerData = JSON.parse(sessionStorage.getItem('optimizerData')) || {};

        if (Object.keys(optimizerData).length > 0) {
            const optimizedContracts = {};
            Object.entries(optimizerData).forEach(([ticker, data]) => {
                const expirationDate = moment(data.bestOption.ExpirationDate).format('YYYY-MM-DD');
                const strike = data.optimal_strike_price.toFixed(2);

                if (!optimizedContracts[ticker]) optimizedContracts[ticker] = [];
                optimizedContracts[ticker].push({
                    stockTicker: ticker,
                    expiration_date: expirationDate,
                    strike: strike,
                    num_contracts: -data.optimal_contracts,
                    description: `${ticker}${expirationDate}C${strike}`,
                    bid_price: data.bestOption?.CloseBidPrice || 0,
                    totalValue: (data.bestOption?.CloseBidPrice || 0) * 100 * data.optimal_contracts,
                    type: 'C',
                    status: 'Pending Open',
                    isOptimized: true,
                });
            });

            optionHoldings.CallWriting = {
                ...(optionHoldings.CallWriting || {}),
                ...optimizedContracts,
            };
        }
        return optionHoldings;
    };

    const flattenAndCategorizeContracts = (optionHoldings) => {
        const callWriting = [];
        const putBuying = [];
        const ShieldRecommendation = [];
        const indexPuts = [];
        if (optionHoldings?.CallWriting) {
            Object.values(optionHoldings.CallWriting).forEach(contracts => {
                contracts.forEach(contract => {
                    if (['Pending Open', 'Pending Close'].includes(contract.status)) {
                        if (contract.contractType === 'C' || (contract.description && contract.description.includes('C'))) {
                            callWriting.push(contract);
                        }
                    }
                });
            });
        }

        if (optionHoldings?.PutBuying) {
            Object.values(optionHoldings.PutBuying).forEach(contracts => {
                contracts.forEach(contract => {
                    if (['Pending Open', 'Pending Close'].includes(contract.status)) {
                        if (contract.contractType === 'P' || (contract.description && contract.description.includes('P'))) {
                            putBuying.push(contract);
                        }
                    }
                });
            });
        }

        if (optionHoldings?.ShieldRecommendation) {
            Object.values(optionHoldings.ShieldRecommendation).forEach(contracts => {
                contracts.forEach(contract => {
                    if (['Pending Open', 'Pending Close'].includes(contract.status)) {
                        ShieldRecommendation.push(contract);
                    }
                });
            });
        }

     if (optionHoldings?.indexPuts) {
            // Iterate over the object values of 'indexPuts'
            Object.values(optionHoldings.indexPuts).forEach(contractsArray => {
                console.log("contractsArray", contractsArray)
                contractsArray.forEach(contract => {
                    if (contract.status === 'Pending Open') {
                        indexPuts.push(contract);
                    }
                });
            });
        }

        return { callWriting, putBuying, ShieldRecommendation, indexPuts };
    };

    useEffect(() => {
        const optionHoldings = JSON.parse(sessionStorage.getItem('existingOptionHoldings')) || {};
        console.log("optionHoldings", optionHoldings)
        const mergedHoldings = mergeOptimizerData(optionHoldings);
        const flattenedContracts = flattenAndCategorizeContracts(mergedHoldings);
        console.log("flattened", flattenedContracts)
        setPendingContracts(flattenedContracts);
    }, []);

    const initializeRow = () => ({
        '#ACCOUNT': '9000',
        'ORDER TYPE': 'LIMIT',
        'LIMIT PRICE': '',
        'LEG 1 SYMBOL': '',
        'LEG 1 QUANTITY': '',
        'LEG 1 OPEN CLOSE': '',
        '': '', 
        '': '', 
        'LEG 2 SYMBOL': '',
        'LEG 2 QUANTITY': '',
        'LEG 2 OPEN CLOSE': '',
        '': '', 
        '': '', 
        'LEG 3 SYMBOL': '',
        'LEG 3 QUANTITY': '',
        'LEG 3 OPEN CLOSE': '',
        '': '', 
        '': '', 
        'LEG 4 SYMBOL': '',
        'LEG 4 QUANTITY': '',
        'LEG 4 OPEN CLOSE': ''
    });
    
    // const initializeRow = (stockSymbol, stockQuantity) => ({
    //     '#ACCOUNT': '9000',
    //     'ORDER TYPE': 'LIMIT',
    //     'LIMIT PRICE': '',
    //     'LEG 1 SYMBOL': stockSymbol || '',
    //     'LEG 1 QUANTITY': stockQuantity || '',
    //     'LEG 1 OPEN CLOSE': 'OPEN',
    //     '': '', 
    //     '': '', 
    //     'LEG 2 SYMBOL': '',
    //     'LEG 2 QUANTITY': '',
    //     'LEG 2 OPEN CLOSE': '',
    //     '': '', 
    //     '': '', 
    //     'LEG 3 SYMBOL': '',
    //     'LEG 3 QUANTITY': '',
    //     'LEG 3 OPEN CLOSE': '',
    //     '': '', 
    //     '': '', 
    //     'LEG 4 SYMBOL': '',
    //     'LEG 4 QUANTITY': '',
    //     'LEG 4 OPEN CLOSE': ''
    // });

    const getAccountNumber = (portfolioDetails) => {
        if (!portfolioDetails) return '9000'; // Default if no portfolio details exist
    
        if (portfolioDetails.schwab_account && portfolioDetails.PortfolioName) {
            // Extract account number from PortfolioName (e.g., "0041731393_DANNY L VARA")
            const match = portfolioDetails.PortfolioName.match(/^0*(\d+)_/);
            return match ? match[1] : '9000'; // Remove leading zeros
        }
    
        return '9000'; // Default for non-schwab accounts
    };
    
    const prepareThinkPipeRowsSingle = (contracts, portfolioDetails, selectedPrice) => {
        if (!contracts || contracts.length === 0) return [];
    
        // Determine the account number dynamically
        const accountNumber = getAccountNumber(portfolioDetails);
    
        return contracts.map((contract) => {
            if (!contract) return null;
    
            const optionSymbol = formatOptionSymbol(contract.description);
            const actionType = contract.status === 'Pending Open' ? 'O' : 'C';
            const side = contract.num_contracts > 0 ? 'B' : 'S';

            const limitPrice =
            selectedPrice === "Bid"
                ? contract.bid_price
                : selectedPrice === "Ask"
                ? contract.ask_price
                : selectedPrice === "Bid/Ask"
                ? ((parseFloat(contract.bid_price) + parseFloat(contract.ask_price)) / 2).toFixed(2)
                : contract.bid_price;

            return {
                '#ACCOUNT': accountNumber, // Use dynamically determined account number
                "LIMIT PRICE": limitPrice || "",
                'QUANTITY': Math.abs(contract.num_contracts),
                'SIDE': side,
                'SYMBOL': optionSymbol,
                'OPEN CLOSE': actionType,
                'TIF': contract.tif || 'DAY',
            };
        }).filter(row => row !== null); // Remove null rows
    };
    
    
    const handleThinkPipeDownloadCsv = () => {
        const allContracts = [
            ...pendingContracts.callWriting,
            ...pendingContracts.putBuying,
            ...pendingContracts.ShieldRecommendation,
            ...pendingContracts.indexPuts,
        ];
    
        if (!allContracts || allContracts.length === 0) {
            dispatch(showNotification("Shopping cart is empty, cannot generate ThinkPipe CSV.", "info", "toaster"));
            return;
        }
    
        // Fetch portfolio details to determine account number
        const portfolioDetails = portfolioDetailsState[portfolioId]?.details || null;
    
        try {
            const formattedRows = prepareThinkPipeRowsSingle(allContracts, portfolioDetails, selectedPrice);
    
            if (formattedRows.length === 0) {
                dispatch(showNotification("No valid data for ThinkPipe CSV.", "info", "toaster"));
                return;
            }
    
            // Create worksheet from the prepared rows
            const worksheet = utils.json_to_sheet(formattedRows);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "ThinkPipe_Trades");
    
            // Generate and download the single CSV file
            writeFile(workbook, "ThinkPipe_Trades.csv", { compression: true });
            dispatch(showNotification("ThinkPipe CSV generated successfully!", "success", "toaster"));
        } catch (error) {
            console.error("Error generating ThinkPipe CSV:", error);
            dispatch(showNotification("Error generating ThinkPipe CSV: " + error.message, "error", "toaster"));
        }
    };
    

    const prepareThinkPipeRows = (contracts) => {
        if (!contracts || contracts.length === 0) return [];

        const rows = [];
        let currentRow = null;
        let legIndex = 1; // Start from LEG 1 for contracts
        let lastTicker = null;

        contracts.forEach((contract, index) => {
            if (!contract) return;

            const optionSymbol = formatOptionSymbol(contract.description);
            const optionQuantity = contract.num_contracts;
            const actionType = contract.status === 'Pending Open' ? 'OPEN' : 'CLOSE';

            if (contract.stockTicker !== lastTicker || legIndex > 4) {
                if (currentRow) rows.push(currentRow);
                currentRow = initializeRow();
                lastTicker = contract.stockTicker;
                legIndex = 1;
            }

            currentRow[`LEG ${legIndex} SYMBOL`] = optionSymbol;
            currentRow[`LEG ${legIndex} QUANTITY`] = optionQuantity;
            currentRow[`LEG ${legIndex} OPEN CLOSE`] = actionType;

            legIndex++;

            if (index === contracts.length - 1) {
                rows.push(currentRow);
            }
        });

        return rows;
    };

    // const prepareThinkPipeRows = (contracts) => {
    //     if (!contracts || contracts.length === 0) return [];

    //     const rows = [];
    //     let currentRow = null;
    //     let legIndex = 2; // Start from Leg 2 for contracts
    //     let lastTicker = null;

    //     contracts.forEach((contract, index) => {
    //         if (!contract) return;

    //         const stockSymbol = contract.stockTicker;
    //         const stockQuantity = contract.num_contracts * 100; // Stock-related quantity
    //         const optionSymbol = formatOptionSymbol(contract.description);
    //         const optionQuantity = contract.num_contracts
    //         const actionType = contract.status === 'Pending Open' ? 'OPEN' : 'CLOSE';

    //         // If it's a new stock ticker or we've reached Leg 4, start a new row
    //         if (contract.stockTicker !== lastTicker || legIndex > 4) {
    //             if (currentRow) rows.push(currentRow); // Push the previous row before starting a new one

    //             // Create a new row with stock details in Leg 1
    //             currentRow = initializeRow(stockSymbol, stockQuantity);
    //             lastTicker = contract.stockTicker;
    //             legIndex = 2; // Start options from Leg 2
    //         }

    //         // Set the details for the current leg
    //         currentRow[`LEG ${legIndex} SYMBOL`] = optionSymbol;
    //         currentRow[`LEG ${legIndex} QUANTITY`] = optionQuantity;
    //         currentRow[`LEG ${legIndex} OPEN CLOSE`] = actionType;

    //         // Move to the next leg
    //         legIndex++;

    //         // If it's the last contract, ensure the row is added to rows
    //         if (index === contracts.length - 1) {
    //             rows.push(currentRow);
    //         }
    //     });

    //     return rows;
    // };

    const renderThinkPipeCard = (contracts, title) => {
        if (!contracts || contracts.length === 0) return null;

        const formattedRows = prepareThinkPipeRows(contracts);

        return (
            <div className="thinkpipe-card">
                <h4>{title}</h4>
                <table className="thinkpipe-table">
                    <thead>
                        <tr>
                            <th>LEG 1 SYMBOL</th>
                            <th>LEG 1 QUANTITY</th>
                            <th>LEG 1 OPEN CLOSE</th>
                            {[...Array(3)].map((_, index) => (
                                <React.Fragment key={index}>
                                    <th>{`LEG ${index + 2} SYMBOL`}</th>
                                    <th>{`LEG ${index + 2} QUANTITY`}</th>
                                    <th>{`LEG ${index + 2} OPEN CLOSE`}</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {formattedRows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{row['LEG 1 SYMBOL']}</td>
                                <td>{row['LEG 1 QUANTITY']}</td>
                                <td>{row['LEG 1 OPEN CLOSE']}</td>
                                {[...Array(3)].map((_, index) => (
                                    <React.Fragment key={index}>
                                        <td>{row[`LEG ${index + 2} SYMBOL`]}</td>
                                        <td>{row[`LEG ${index + 2} QUANTITY`]}</td>
                                        <td>{row[`LEG ${index + 2} OPEN CLOSE`]}</td>
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button
                    className='ai-btn primary line'
                    onClick={() => handleConfirmTrade(contracts)}
                >
                    Confirm Trade
                </button>
            </div>
        );
    };

    // const handleThinkPipeDownload = () => {
    //     const allContracts = [
    //         ...pendingContracts.callWriting,
    //         ...pendingContracts.putBuying,
    //         ...pendingContracts.ShieldRecommendation,
    //         ...pendingContracts.indexPuts
    //     ];

    //     if (!allContracts || allContracts.length === 0) {
    //         dispatch(showNotification("Shopping cart is empty, cannot generate ThinkPipe CSV.", "info", "toaster"));
    //         return;
    //     }

    //     try {
    //         const formattedRows = prepareThinkPipeRows(allContracts);

    //         if (formattedRows.length === 0) {
    //             dispatch(showNotification("No valid data for ThinkPipe CSV", "info", "toaster"));
    //             return;
    //         }

    //         const worksheet = utils.json_to_sheet(formattedRows.map(row => ({
    //             '#ACCOUNT': row['#ACCOUNT'],
    //             'ORDER TYPE': row['ORDER TYPE'],
    //             'LIMIT PRICE': row['LIMIT PRICE'],
    //             'LEG 1 SYMBOL': row['LEG 1 SYMBOL'],
    //             'LEG 1 QUANTITY': row['LEG 1 QUANTITY'],
    //             'LEG 1 OPEN CLOSE': row['LEG 1 OPEN CLOSE'],
    //             '': '', 
    //             '': '', 
    //             'LEG 2 SYMBOL': row['LEG 2 SYMBOL'],
    //             'LEG 2 QUANTITY': row['LEG 2 QUANTITY'],
    //             'LEG 2 OPEN CLOSE': row['LEG 2 OPEN CLOSE'],
    //             '': '', 
    //             '': '', 
    //             'LEG 3 SYMBOL': row['LEG 3 SYMBOL'],
    //             'LEG 3 QUANTITY': row['LEG 3 QUANTITY'],
    //             'LEG 3 OPEN CLOSE': row['LEG 3 OPEN CLOSE'],
    //             '': '', 
    //             '': '', 
    //             'LEG 4 SYMBOL': row['LEG 4 SYMBOL'],
    //             'LEG 4 QUANTITY': row['LEG 4 QUANTITY'],
    //             'LEG 4 OPEN CLOSE': row['LEG 4 OPEN CLOSE']
    //         })));

    //         const workbook = utils.book_new();
    //         utils.book_append_sheet(workbook, worksheet, "ThinkPipe_Trade");
    //         writeFile(workbook, "ThinkPipe_Trade.csv", { compression: true });

    //         dispatch(showNotification("ThinkPipe CSV generated successfully!", "success", "toaster"));
    //     } catch (error) {
    //         console.error("Error generating ThinkPipe CSV:", error);
    //         dispatch(showNotification("Error generating ThinkPipe CSV: " + error.message, "error", "toaster"));
    //     }
    // };

    // const handleThinkPipeDownload = () => {
    //     const allContracts = [
    //         ...pendingContracts.callWriting,
    //         ...pendingContracts.putBuying,
    //         ...pendingContracts.ShieldRecommendation
    //     ];

    //     if (!allContracts || allContracts.length === 0) {
    //         dispatch(showNotification("Shopping cart is empty, cannot generate ThinkPipe CSV.", "info", "toaster"));
    //         return;
    //     }

    //     try {
    //         const formattedRows = prepareThinkPipeRows(allContracts);

    //         if (formattedRows.length === 0) {
    //             dispatch(showNotification("No valid data for ThinkPipe CSV", "info", "toaster"));
    //             return;
    //         }

    //         // Prepare the worksheet with additional empty columns between legs
    //         const worksheet = utils.json_to_sheet(formattedRows.map(row => ({
    //             '#ACCOUNT': row['#ACCOUNT'],
    //             'ORDER TYPE': row['ORDER TYPE'],
    //             'LIMIT PRICE': row['LIMIT PRICE'],
    //             'LEG 1 SYMBOL': row['LEG 1 SYMBOL'],
    //             'LEG 1 QUANTITY': row['LEG 1 QUANTITY'],
    //             'LEG 1 OPEN CLOSE': row['LEG 1 OPEN CLOSE'],
    //             '': '', // Empty column between legs
    //             '': '', // Another empty column
    //             'LEG 2 SYMBOL': row['LEG 2 SYMBOL'],
    //             'LEG 2 QUANTITY': row['LEG 2 QUANTITY'],
    //             'LEG 2 OPEN CLOSE': row['LEG 2 OPEN CLOSE'],
    //             '': '', // Empty column between legs
    //             '': '', // Another empty column
    //             'LEG 3 SYMBOL': row['LEG 3 SYMBOL'],
    //             'LEG 3 QUANTITY': row['LEG 3 QUANTITY'],
    //             'LEG 3 OPEN CLOSE': row['LEG 3 OPEN CLOSE'],
    //             '': '', // Empty column between legs
    //             '': '', // Another empty column
    //             'LEG 4 SYMBOL': row['LEG 4 SYMBOL'],
    //             'LEG 4 QUANTITY': row['LEG 4 QUANTITY'],
    //             'LEG 4 OPEN CLOSE': row['LEG 4 OPEN CLOSE']
    //         })));

    //         const workbook = utils.book_new();
    //         utils.book_append_sheet(workbook, worksheet, "ThinkPipe_Trade");
    //         writeFile(workbook, "ThinkPipe_Trade.csv", { compression: true });

    //         dispatch(showNotification("ThinkPipe CSV generated successfully!", "success", "toaster"));
    //     } catch (error) {
    //         console.error("Error generating ThinkPipe CSV:", error);
    //         dispatch(showNotification("Error generating ThinkPipe CSV: " + error.message, "error", "toaster"));
    //     }
    // };

    const formatContractForDisplay = (symbol) => {
        if (!symbol) return '';
        const match = symbol.match(/^([A-Z]+)(\d{4})(\d{2})(\d{2})([CP])(\d+(\.\d{1,2})?)$/);
        if (!match) return symbol;

        const [, ticker, year, month, day, type, strike] = match;
        return `.${ticker}${year}${month}${day}${type}${parseFloat(strike).toFixed(2)}`;
    };

    const renderShoppingCard = (contracts, title) => {
        if (!contracts || contracts.length === 0) return null;

        const totalPremium = contracts.reduce((acc, contract) => acc + (contract.totalValue || 0), 0);

        return (
            <div className='shopping-card'>
                <h4>{title}</h4>
                {contracts.map((contract, index) => {
                    const displayContract = formatContractForDisplay(contract.description);
                    const displayQty = contract.num_contracts;
                    const displayPremium = contract.totalValue
                        ? `: ${Number(addZeroes(contract.totalValue)).toLocaleString("en-US", { style: "currency", currency: "USD" })}`
                        : '';

                    return (
                        <div key={index} className="item-row">
                            <span>{displayContract}</span>
                            <span>{contract.type === 'C' ? 'CALL' : 'PUT'}</span>
                            <span className="quantity">{displayQty}</span>
                            <span className="premium">{displayPremium}</span>
                        </div>
                    );
                })}
                {totalPremium > 0 && (
                    <div className="totalprice premium">
                        Total: {Number(addZeroes(totalPremium)).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    </div>
                )}
                <button
                    className='ai-btn primary line'
                    onClick={() => handleConfirmTrade(contracts)}
                >
                    Confirm Trade
                </button>
            </div>
        );
    };
    const prepareApiData = (contracts) => {
        return {
            portfolio_id: portfolioId,
            option_contracts: contracts.map(contract => ({
                symbol: contract.stockTicker,
                expiration_date: contract.expiration_date,
                type: contract.type,
                strike: contract.strike,
                bid_price: contract.bid_price,
                description: `${contract.stockTicker}${contract.expiration_date}${contract.type}${parseFloat(contract.strike).toFixed(2)}`,
                num_contracts: contract.num_contracts,
                totalValue: contract.totalValue,
                // is_shield: contract.isShield,
                action: contract.status === 'Pending Open' ? 'open' : 'close'
            }))
        };
    };
    
    const handleConfirmTrade = async (contracts) => {
        const apiData = prepareApiData(contracts);
        apiData.optimizer = false

        try {
            RollOrSell(apiData).then((data) => {
            if (data.status === 200) {
                dispatch(showNotification('Trade confirmed successfully!', 'success', 'toaster'));
                dispatch(setPortfolioNeedsUpdate(portfolioId));
                dispatch(fetchPortfolioDetailsIfNeeded(portfolioId));
            } else {
                dispatch(showNotification('Failed to confirm trade. Please try again.', 'error', 'toaster'));
            }
        }).catch(error => {
            dispatch(showNotification('Error confirming trade: ' + error.message, 'error', 'toaster'));
            handleApiError(error, dispatch);
        });
        } catch (error) {
            dispatch(showNotification('Error confirming trade: ' + error.message, 'error', 'toaster'));
        }
    };
        // Download standard CSV
        
        
        const handleDownloadCSV = () => {
            const allContracts = [
                ...pendingContracts.callWriting,
                ...pendingContracts.putBuying,
                ...pendingContracts.ShieldRecommendation,
                ...pendingContracts.indexPuts
            ];
    
            if (!allContracts || allContracts.length === 0) {
                dispatch(showNotification("Shopping cart is empty, cannot generate CSV.", "info", "toaster"));
                return;
            }
    
            const csvRows = allContracts.map(contract => ({
                'Symbol': formatContractForDisplay(contract.description),
                'Type': contract.contractType === 'C' ? 'CALL' : 'PUT',
                'Quantity': contract.num_contracts,
                'Total Value': contract.totalValue ? Number(addZeroes(contract.totalValue)).toLocaleString("en-US", { style: "currency", currency: "USD" }) : ''
            }));
    
            const worksheet = utils.json_to_sheet(csvRows);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Contracts");
            writeFile(workbook, "Contracts.csv", { compression: true });
    
            dispatch(showNotification("CSV generated successfully!", "success", "toaster"));
        };

    return (
        <div className="shopping-cart">
            {renderThinkPipeCard(pendingContracts.indexPuts, 'Pending Index Puts')}
            {renderThinkPipeCard(pendingContracts.putBuying, 'Pending Puts')}
            {renderThinkPipeCard(pendingContracts.callWriting, 'Pending Covered Calls')}
            {renderThinkPipeCard(pendingContracts.ShieldRecommendation, 'Pending Shield Contracts')}
            <div className='right-button-container drawerbottom actions'>
                <button className="ai-btn line blue" onClick={handleThinkPipeDownloadCsv}>
                    Download ThinkPipes CSV
                </button>
                <button className="ai-btn line blue" onClick={handleDownloadCSV}>
                    Download CSV
                </button>
            </div>
        </div>
    );
};

export default ShoppingCart;
