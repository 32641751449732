import {
    FETCH_SHIELD_DATA_REQUEST,
    FETCH_SHIELD_DATA_SUCCESS,
    FETCH_SHIELD_DATA_FAILURE,
    SET_SELECTED_INDEX,
    SET_SHIELD_DATE, 
} from '../actions/shieldDataActions';

const initialState = {
    data: {},
    loading: {},
    error: {},
    selectedIndex: null,
    protectionPeriod: {}, 
};

const shieldDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHIELD_DATA_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.payload]: true
                },
                error: {
                    ...state.error,
                    [action.payload]: null
                }
            };
        case FETCH_SHIELD_DATA_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.data
                },
                loading: {
                    ...state.loading,
                    [action.payload.key]: false
                },
                error: {
                    ...state.error,
                    [action.payload.key]: null
                }
            };
        case FETCH_SHIELD_DATA_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.payload.key]: false
                },
                error: {
                    ...state.error,
                    [action.payload.key]: action.payload.error
                }
            };
        case SET_SELECTED_INDEX:
                return {
                    ...state,
                    selectedIndex: action.payload
                };
        case SET_SHIELD_DATE:
            return {
                ...state,
                protectionPeriod: {
                    ...state.protectionPeriod,
                    [action.payload.portfolioId]: action.payload.date,
                },
            };
        default:
            return state;
    }
};

export default shieldDataReducer;
