export function CheckSessionTimeout(token) {
    if (token !== "") {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            const isTokenExpired = Date.now() >= payload.exp * 1000;
            return isTokenExpired;
        } catch (error) {
            return true;
        }
    } else {
        return true;
    }
}
